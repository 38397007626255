import React from "react"

import * as styles from "./disclaimerBountyContest.module.scss"
import "../../../static.css"
import { TM } from "../../common/typography/trademark"

const DisclaimerBountyContest = () => (
  <div className="row">
    <div className={styles.disclaimer}>
      <u>Disclaimer</u>
      <p>
        The IronCAP
        <TM /> Live Demo Agreement, as amended from time to time (the
        "Agreement"), applies when you access or use the Live Demo under this
        Agreement. In consideration for us providing you with access to the
        IronCAP
        <TM /> Live Demo (Demo), you agree that this Agreement and any other
        applicable agreements will govern all matters pertaining to your Demo
        account with 01 Communique (the Company). You also agree to comply with
        all instructions we may give you in connection with accessing and using
        the Demo. The Company reserves the right, in its sole discretion, to
        suspend or cancel your access to the Demo without giving you notice if
        we believe you are: (a) violating the IronCAP
        <TM /> Live Demo Agreement; (b) tampering or attempting to tamper with
        the operation of the Demo or the Company’s websites; (c) using it
        inappropriately or if there is unusual activity in or relating to your
        Live Demo Account; (d) violating the terms of service, conditions of use
        and/ or general rules or guidelines of any of the Company's property or
        service and/ or (e) acting with the intent to annoy, abuse, threaten or
        harass any other person. Not all of the same functionality or features
        are accessible or available for the Live Demo accounts as may be
        available for an active IronCAP
        <TM /> business account. The Company may modify the functionalities and
        services without giving notice to you. In no event will we, or our
        affiliates, be liable to you or others for any damages, direct,
        indirect, consequential or special, including, without limitation,
        losses, costs, expenses, loss of profits or loss of business revenues
        out of the existence, furnishing, or functioning of the Demo, or any act
        or omission in connection with you accessing the Demo and the Company’s
        websites.
      </p>
    </div>
  </div>
)

export default DisclaimerBountyContest
