import React, { Component } from "react"
import * as styles from "./submissionBountyContest.module.scss"
import Popup from "reactjs-popup"
import spinner from "../../../../assets/spinner.gif"
import "../../../static.css"
import { TM } from "../../common/typography/trademark"
const Recaptcha = require("react-recaptcha")

class SubmissionBountyContest extends Component {
  constructor(props) {
    super(props)

    this.state = { isVerified: false }
    this.closePopupModal = this.closePopupModal.bind(this)
  }

  recaptchaLoaded = () => {
    console.log("recaptcha loaded")
  }

  verifyCallback = res => {
    this.setState({ isVerified: true })
  }

  closePopupModal() {
    this.setState({ isVerified: false })
    this.props.closeModal()
  }

  showEmailView() {
    let emailBtnClass = " form-control btn btn-sm col-md-8 btn-submission"
    let cancelBtnClass =
      " form-control btn btn-sm col-md-3 btn-submission float-right"
    let cancelBtnAfterEmailClass =
      " form-control btn btn-sm col-md-3 btn-submission"

    if (this.props.isSubmissionSuccess === true) {
      return (
        <div>
          <div className={styles.gap}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="col-md-12">
                <div className={styles.inputuserheading}>
                  <p>
                    The encrypted data has been sent to <br /> the following
                    email address:
                  </p>

                  <p>{this.props.email}</p>

                  <p className="text-muted">
                    ( Please check both your Inbox and <br />
                    Spam folder for the encrypted data )
                  </p>

                  <p>
                    <button
                      className={cancelBtnAfterEmailClass}
                      onClick={() => {
                        this.closePopupModal()
                      }}
                    >
                      {" "}
                      Close{" "}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className={styles.gap}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="col-md-12">
                <div className={styles.inputuserheading}>
                  Encrypted by IronCAP
                  <TM />
                </div>

                <div className={styles.inputuserwrapper}>
                  {this.showEncryptedData()}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.gap}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="col-md-2">Email:</div>

              <div className="col-md-10">
                <input
                  type="email"
                  autoFocus
                  className="form-control"
                  onChange={this.props.handleSetEmail}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-center align-items-center">
            <div className="offset-md-2 col-md-10">
              <br />
              <Recaptcha
                className={styles.recaptcha}
                sitekey="6LeoUJ8UAAAAAEABtDiHsascEeo4WOMiG-47E07j"
                onloadCallback={this.recaptchaLoaded}
                verifyCallback={this.verifyCallback}
                render="explicit"
                async="false"
                defer="false"
                data-size="compact"
              />
              <br />
              <button
                disabled={!this.state.isVerified}
                className={emailBtnClass}
                onClick={() => {
                  this.props.handleEmailEncryptedData()
                }}
              >
                Send encrypted data by email
              </button>

              <button
                className={cancelBtnClass}
                onClick={() => {
                  this.closePopupModal()
                }}
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
          </div>

          {this.props.progressIndicatorSub === true ? (
            <div className={styles.gap}>
              <div className="row ">
                <div className="col-md-12">
                  <span className={styles.progressIndicator}>
                    Please wait ... <img src={spinner} width="20" />{" "}
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {this.props.errorSubmission ? (
            <div className={styles.gap}>
              <div className="row">
                <div className="col-md-12">
                  <span className={styles.errorMsg}>
                    {" "}
                    Error: {this.props.errorSubmission}{" "}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )
    }
  }

  showEncryptedData() {
    if (this.props.isToolDisplayFile === 0) {
      return (
        <React.Fragment>
          <textarea
            required="required"
            id="description"
            rows="6"
            readOnly="readOnly"
            className={styles.formcontrol}
            placeholder="Original input text"
            onChange={this.props.handleEnableSubmissionButton}
            defaultValue={this.props.encryptedText}
          />
        </React.Fragment>
      )
    } else {
      return (
        <div className={styles.inputuserwrapper}>
          <input
            type="hidden"
            id="description"
            defaultValue={this.props.submissionText}
          />

          <div className={styles.downloadborder}>
            <div className={styles.downloadheader}>
              {this.props.submissionText + ".icc"}
            </div>

            <div className={styles.downloadprogress}></div>

            <div>
              The above encrypted file can be found in your download folder.
              Alternatively, you may also send it via email.
            </div>
          </div>
        </div>
      )
    }
  }

  showSubmission() {
    return (
      <React.Fragment>
        <div className={styles.content}>
          <div className={styles.gap}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="col-md-12">
                <div className={styles.inputuserheading}>Original</div>

                <div className={styles.inputuserwrapper}>
                  <div className={styles.download}>
                    {this.props.submissionText}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.showEmailView()}
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <Popup
        className="submission"
        open={this.props.open}
        closeOnDocumentClick
        onClose={this.closePopupModal}
      >
        {close => (
          <div className={styles.modal}>
            <a className={styles.close} onClick={this.closePopupModal}>
              &times;
            </a>

            {this.showSubmission()}
          </div>
        )}
      </Popup>
    )
  }
}

export default SubmissionBountyContest
