import React, { Component } from "react"
import Layout from "../../components/layout"

import AboutBountyContest from "../../components/sections/cgiDemo/aboutBountyContest"
import ToolBountyContest from "../../components/sections/cgiDemo/toolBountyContest"
import SubmissionBountyContest from "../../components/sections/cgiDemo/submissionBountyContest"
import DisclaimerBountyContest from "../../components/sections/cgiDemo/disclaimerBountyContest"

import axios from "axios"
import FileDownload from "js-file-download"

import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "./cgi.module.scss"
import HowItWorks from "../../components/sections/howItWorks/howItWorks"
import "../../static.css"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"

class CGIDemo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMsg: null,
      selectedEncryptFile: null,
      selectedDecryptFile: null,
      agreementID: "39",
      userID: "3c1dd879d4c204a840329e0c02d92d21",
      mode: "accept",
      enableAgreement: false,
      open: false,
      email: null,
      firstname: "cgidemo",
      lastname: "cgidemo",
      logintype: "d",
      token: "cgidemo",
      photo: "",
      submissionlastDt: null,
      submissionTotal: null,
      answerSubmitted: false,
      submissionText: null,
      errorTool: null,
      errorToolDecryption: null,
      errorToolEncryption: null,
      errorSubmission: null,
      isSubmissionSuccess: null,
      subscription: "y",
      isToolDisplayFile: 0,
      enableToolBtnEncryption: "disabled",
      enableToolBtnDecryption: "disabled",
      encryptedText: null,
      progressIndicatorEnc: false,
      progressIndicatorDec: false,
      progressIndicatorSub: false,
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.handleEncryptionBinary = this.handleEncryptionBinary.bind(this)
    this.handleDecryptionBinary = this.handleDecryptionBinary.bind(this)

    this.handleSubmission = this.handleSubmission.bind(this)
    this.handleSetEmail = this.handleSetEmail.bind(this)
    this.handleEmailEncryptedData = this.handleEmailEncryptedData.bind(this)
    this.onChangeUploadFileEncryptHandler = this.onChangeUploadFileEncryptHandler.bind(
      this
    )
    this.onChangeUploadFileDecryptHandler = this.onChangeUploadFileDecryptHandler.bind(
      this
    )

    this.handleEnableSubmissionButton = this.handleEnableSubmissionButton.bind(
      this
    )
    this.handleToolDisplayText = this.handleToolDisplayText.bind(this)
    this.handleToolDisplayFile = this.handleToolDisplayFile.bind(this)

    this.handleValidateText = this.handleValidateText.bind(this)
  }

  handleToolDisplayText(e) {
    if (this.state.isToolDisplayFile === 1) {
      this.setState({
        isToolDisplayFile: 0,
        selectedEncryptFile: null,
        errorToolEncryption: null,
        enableToolBtnEncryption: "disabled",
      })
    }
  }

  handleToolDisplayFile(e) {
    if (this.state.isToolDisplayFile === 0) {
      this.setState({
        isToolDisplayFile: 1,
        errorToolEncryption: null,
        enableToolBtnEncryption: "disabled",
      })
    }
  }

  openModal() {
    this.setState({
      open: true,
      isSubmissionSuccess: null,
      errorSubmission: null,
      email: null,
    })
  }

  closeModal() {
    this.setState({
      answerSubmitted: false,
      open: false,
      errorSubmission: null,
    })
  }

  closeModalWithAccept() {
    var data = {
      id: this.state.userID,
      subscription: this.state.subscription,
      logintype: this.state.logintype,
      token: this.state.token,
    }
    let url = this.getApiURL("subscription")
    let self = this

    axios
      .post(url, data)
      .then(function(response) {
        // let results = response.data
        self.setState({ open: false, mode: "agreement" })
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }

        if (error && error.response && error.response.status === 400) {
          self.setState({ errorMsg: error.response.data.message })
        }
      })
  }

  getExtension(filename) {
    var i = filename.lastIndexOf(".")
    return i < 0 ? "" : filename.substr(i).toLowerCase()
  }

  handleValidateText(e) {
    this.setState({ enableToolBtnEncryption: null })
  }

  onChangeUploadFileEncryptHandler(e) {
    if (e.target.files.length > 0) {
      let size = e.target.files[0].size

      if (size > 2097152) {
        this.setState({
          errorToolEncryption: "File size must be smaller than 2MB.",
        })
        return
      }

      this.setState({
        selectedEncryptFile: e.target.files[0],
        enableToolBtnEncryption: null,
        errorToolEncryption: null,
      })
    } else {
      this.setState({
        selectedEncryptFile: null,
        enableToolBtnEncryption: "disabled",
        errorToolEncryption: null,
      })
    }
  }

  onChangeUploadFileDecryptHandler(e) {
    this.setState({
      selectedDecryptFile: e.target.files[0],
      enableToolBtnDecryption: null,
      errorToolDecryption: null,
    })
  }

  handleEnableSubmissionButton(event) {
    this.setState({ submissionText: event.target.value.trim() })
  }

  handleSetEmail(event) {
    this.setState({ email: event.target.value.trim() })
  }

  handleEmailEncryptedData() {
    if (this.state.email === null || this.state.email.trim() === "") {
      this.setState({ errorSubmission: "You must enter an Email address" })
      return
    }

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!re.test(this.state.email)) {
      this.setState({ errorSubmission: "You must enter a valid Email address" })
      return
    }

    let fileName =
      this.state.selectedEncryptFile === null
        ? "encrypted.icc"
        : this.state.selectedEncryptFile.name + ".icc"

    var data = {
      id: this.state.userID,
      agreement_id: this.state.agreementID,
      logintype: this.state.logintype,
      token: this.state.token,
      encryptedText: this.state.encryptedText,
      email: this.state.email,
      filename: fileName,
    }
    var self = this
    self.setState({
      errorSubmission: null,
      isSubmissionSuccess: null,
      progressIndicatorSub: true,
    })

    let url = this.getApiURL("cgisubmission")

    axios
      .post(url, data)
      .then(function(response) {
        self.setState({
          progressIndicatorSub: false,
          isSubmissionSuccess: true,
        })
      })
      .catch(function(error) {
        self.setState({ progressIndicatorSub: false })

        if (error && error.response && error.response.status === 400) {
          self.setState({ errorSubmission: error.response.data.message })
        }

        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  handleEncryptionBinary(e) {
    e.preventDefault()

    let txtIn = document.getElementById("textIn")
    var buffer = null
    var input = null

    if (txtIn) {
      buffer = Buffer.from(txtIn.value, "utf8")
      input = buffer.toString("hex")
      var len = Buffer.byteLength(buffer)
      if (len > 2097152) {
        this.setState({ errorToolEncryption: "Max 2MB is allowed" })
        return
      }
    }

    const data = new FormData()
    //  let txtIn = document.getElementById("description");
    data.append("id", this.state.userID)
    data.append("agreement_id", this.state.agreementID)
    data.append("logintype", this.state.logintype)
    data.append("token", this.state.token)

    data.append("convert", "e")
    data.append("out", "binary")

    if (this.state.isToolDisplayFile) {
      data.append("file", this.state.selectedEncryptFile)
    } else {
      data.append("input", input)
    }

    data.append("isFileUpload", this.state.isToolDisplayFile)

    // var data = {id:this.state.userID,agreement_id:this.state.agreementID, logintype:this.state.logintype,token:this.state.token,input:input,"convert":"e",out:"binary" };;

    let url = this.getApiURL("cgitool")
    let self = this
    // let fileName =
    //   this.state.selectedEncryptFile === null
    //     ? "encrypted.icc"
    //     : this.state.selectedEncryptFile.name + ".icc"

    self.setState({ progressIndicatorEnc: true })

    axios
      .post(url, data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then(function(response) {
        self.state.encryptedText = response.data.data
        self.setState({ progressIndicatorEnc: false })

        if (self.state.isToolDisplayFile == 1) {
          self.state.submissionText = self.state.selectedEncryptFile.name

          let fileName = self.state.selectedEncryptFile.name + ".icc"
          let bufferOut = Buffer.from(response.data.data)
          FileDownload(bufferOut, fileName)
        } else {
          self.state.submissionText = txtIn.value
        }

        self.openModal()
      })
      .catch(function(error) {
        self.setState({ progressIndicatorEnc: false })

        if (error && error.response && error.response.status === 400) {
          self.setState({ errorToolEncryption: error.response.data.message })
        }

        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  handleDecryptionBinary(e) {
    e.preventDefault()

    const data = new FormData()
    data.append("id", this.state.userID)
    data.append("agreement_id", this.state.agreementID)
    data.append("logintype", this.state.logintype)
    data.append("token", this.state.token)

    data.append("convert", "d")
    data.append("out", "binary")
    data.append("file", this.state.selectedDecryptFile)
    data.append("isFileUpload", this.state.isToolDisplayFile)

    //  var data = {id:this.state.userID,agreement_id:this.state.agreementID, logintype:this.state.logintype,token:this.state.token,input:input,"convert":"d",out:"binary" };

    let url = this.getApiURL("cgitool")
    let self = this

    self.setState({ progressIndicatorDec: true })

    axios
      .post(url, data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then(function(response) {
        self.setState({ progressIndicatorDec: false })
        let bufferOut = Buffer.from(response.data.data)
        let filename = response.data.fileUploadedName
        FileDownload(bufferOut, filename)
      })
      .catch(function(error) {
        self.setState({ progressIndicatorDec: false })

        if (error && error.response && error.response.status === 400) {
          self.setState({ errorToolDecryption: error.response.data.message })
        }

        if (error && error.response && error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  // this is for popup , used in two places.
  handleSubmission() {
    this.setState({
      selectedEncryptFile: null,
      resultMatched: null,
      errorSubmission: null,
      isSubmissionSuccess: null,
      open: true,
      submissionText: null,
    })
  }

  handleLastSubmissionData() {
    var data = {
      id: this.state.userID,
      agreement_id: this.state.agreementID,
      logintype: this.state.logintype,
      token: this.state.token,
    }
    let url = this.getApiURL("submission/list")
    let self = this

    axios
      .post(url, data)
      .then(function(response) {
        let results = response.data
        self.setState({
          submissionlastDt: results.lastrec,
          submissionTotal: results.total,
        })
      })
      .catch(function(error) {
        if (error.response.status === 440) {
          self.setState({ mode: "welcome" })
        }
      })
  }

  componentClicked = () => {}

  showWelcome() {
    const windowGlobal = typeof window !== "undefined" && window
    if (windowGlobal) {
      windowGlobal.scrollTo(0, 0)
    }

    if (this.state.mode === "accept") {
      return (
        <React.Fragment>
          <ToolBountyContest
            mode={this.state.mode}
            handleEncryptionBinary={this.handleEncryptionBinary}
            handleDecryptionBinary={this.handleDecryptionBinary}
            handleSubmission={this.handleSubmission}
            email={this.state.email}
            logintype={this.state.logintype}
            photo={this.state.photo}
            handleLogout={this.handleLogout}
            submissionlastDt={this.state.submissionlastDt}
            submissionTotal={this.state.submissionTotal}
            handleLastSubmissionData={this.handleLastSubmissionData}
            errorToolEncryption={this.state.errorToolEncryption}
            errorToolDecryption={this.state.errorToolDecryption}
            handleToolDisplayText={this.handleToolDisplayText}
            handleToolDisplayFile={this.handleToolDisplayFile}
            isToolDisplayFile={this.state.isToolDisplayFile}
            onChangeUploadFileEncryptHandler={
              this.onChangeUploadFileEncryptHandler
            }
            onChangeUploadFileDecryptHandler={
              this.onChangeUploadFileDecryptHandler
            }
            handleValidateText={this.handleValidateText}
            enableToolBtnEncryption={this.state.enableToolBtnEncryption}
            enableToolBtnDecryption={this.state.enableToolBtnDecryption}
            progressIndicatorEnc={this.state.progressIndicatorEnc}
            progressIndicatorDec={this.state.progressIndicatorDec}
          />

          <SubmissionBountyContest
            open={this.state.open}
            email={this.state.email}
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            closeModal={this.closeModal}
            handleEmailEncryptedData={this.handleEmailEncryptedData}
            handleSetEmail={this.handleSetEmail}
            mode={this.state.mode}
            isSubmissionSuccess={this.state.isSubmissionSuccess}
            errorSubmission={this.state.errorSubmission}
            encryptedText={this.state.encryptedText}
            submissionText={this.state.submissionText}
            isToolDisplayFile={this.state.isToolDisplayFile}
            progressIndicatorSub={this.state.progressIndicatorSub}
          />
        </React.Fragment>
      )
    }
  }

  getApiURL(param) {
    return `${process.env.DEMO_SERVER_API_URL}/${param}`
  }

  render() {
    return (
      <Layout>
        <input type="hidden" name="acctType" value={this.state.logintype} />
        <input type="hidden" name="token" value={this.state.token} />

        <div id={styles.carouselContainer}>{this.showWelcome()}</div>

        <AboutBountyContest />

        <HowItWorks />

        <DisclaimerBountyContest />

        <CTABanner
          tagline={LearnMoreTagline}
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    )
  }
}

export default CGIDemo
