import React, { Component } from "react"
import * as styles from "./toolBountyContest.module.scss"
import ProgressiveImage from "react-progressive-image-loading"
import banner3bg from "../../../../assets/Home-BountyContest.png"
import banner3bgmin from "../../../../assets/Home-BountyContest.png"
import protectedByLogo from "../../../../assets/IronCAP -protected by- logo black (480 x 110).png"
import spinner from "../../../../assets/spinner.gif"

class ToolBountyContest extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  displaySubmission() {
    if (this.props.submissionTotal > 0) {
      return (
        <div className={styles.contestheading}>
          Last Attempt : {this.props.submissionlastDt}
          <br />
          Total Attempt : {this.props.submissionTotal}
        </div>
      )
    }
  }

  displayControl() {
    if (this.props.isToolDisplayFile == 0)
      return (
        <textarea
          id="textIn"
          autoFocus
          className="form-control mr-2"
          placeholder="Write something to encrypt"
          cols="25"
          rows="2"
          onChange={this.props.handleValidateText}
        />
      )
    else {
      return (
        <div className="sectionFileUpload mr-2">
          <div>
            <input
              type="file"
              className="form-control-file"
              id="uploadfile"
              onChange={this.props.onChangeUploadFileEncryptHandler}
            />
          </div>
          <div className="text-muted">Upload a file to encrypt (Max 2MB)</div>
        </div>
      )
    }
  }

  render() {
    var textClassName =
      this.props.isToolDisplayFile == 0 ? "btn-tool-primary" : "btn-tool"
    var fileClassName =
      this.props.isToolDisplayFile == 1 ? "btn-tool-primary" : "btn-tool"

    return (
      <ProgressiveImage
        preview={banner3bgmin}
        src={banner3bg}
        transitionTime={500}
        transitionFunction="ease"
        render={(src, style) => (
          <div
            className={styles.carouselGraphic3}
            style={Object.assign(style, { backgroundImage: `url(${src})` })}
          >
            <div className={styles.carouselText}></div>

            <div className={styles.carouselImg}>
              <div className="row">
                <div className={styles.contestheading}>
                  <div className={styles.toolHeading}>
                    Quantum-safe Cryptography
                  </div>

                  <div>
                    <img
                      src={protectedByLogo}
                      className={styles.logo}
                      alt="IronCAP™ logo"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className={styles.contestheading}>
                  <button id="btnLogout" onClick={this.props.handleLogout}>
                    Logout
                  </button>
                </div>
              </div>

              <div className="row ">
                <div className="toolsection">
                  <span className="text">Encryption</span>
                </div>
              </div>

              <div className="row top-buffer">{this.displaySubmission()}</div>

              <div className="row">
                <div className={styles.contestheading}>
                  <div className="input-group noWrap">
                    <div className="mr-2">
                      <button
                        className={` mb-2 btn btn-sm ${textClassName}`}
                        onClick={this.props.handleToolDisplayText}
                      >
                        Write Something{" "}
                      </button>
                      <br />
                      <button
                        className={` mb-2 btn btn-sm ${fileClassName}`}
                        onClick={this.props.handleToolDisplayFile}
                      >
                        Upload a File
                      </button>
                    </div>

                    {this.displayControl()}

                    <button
                      className="btn btn-sm btn-tool"
                      disabled={this.props.enableToolBtnEncryption}
                      onClick={this.props.handleEncryptionBinary}
                    >
                      Encrypt &gt;
                    </button>
                  </div>
                </div>
              </div>

              {this.props.progressIndicatorEnc === true ? (
                <div className="row ">
                  <div className={styles.contestheading}>
                    <span className={styles.progressIndicator}>
                      Please wait ... <img src={spinner} width="20" />{" "}
                    </span>
                  </div>
                </div>
              ) : null}

              {this.props.errorToolEncryption ? (
                <div className="row ">
                  <div className={styles.contestheading}>
                    <span className={styles.errorMsg}>
                      Error: {this.props.errorToolEncryption}
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="row mt-2 ">
                <div className="toolsection">
                  <span className="text">Decryption</span>
                </div>
              </div>

              <div className="row top-buffer">
                <div className={styles.contestheading}>
                  <div className="input-group noWrap">
                    <span className={styles.contestNotes}>
                      <u>Notes:</u>
                      <br />
                      Only those files you
                      <br /> encrypted previously
                      <br /> can be decrypted here.
                    </span>
                    <div className="sectionFileUpload mr-2">
                      <input
                        type="file"
                        className="form-control-file"
                        onChange={this.props.onChangeUploadFileDecryptHandler}
                      />
                    </div>
                    <button
                      className="btn btn-sm btn-tool from-control"
                      disabled={this.props.enableToolBtnDecryption}
                      onClick={this.props.handleDecryptionBinary}
                    >
                      {" "}
                      Decrypt &gt;{" "}
                    </button>
                  </div>
                </div>
              </div>

              {this.props.progressIndicatorDec === true ? (
                <div className="row ">
                  <div className={styles.contestheading}>
                    <span className={styles.progressIndicator}>
                      Please wait ... <img src={spinner} width="20" />{" "}
                    </span>
                  </div>
                </div>
              ) : null}

              {this.props.errorToolDecryption ? (
                <div className="row ">
                  <div className={styles.contestheading}>
                    <span className={styles.errorMsg}>
                      Error: {this.props.errorToolDecryption}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      />
    )
  }
}

export default ToolBountyContest
